.header {
  display: flex;
  justify-content: space-between;
  padding: $spacing-03 0;

  & nav {
    display: grid;
    grid-auto-flow: column;
    column-gap: $spacing-02;
  }
}
