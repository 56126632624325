.home {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: $spacing-05;
  margin-bottom: $spacing-03;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

// Index section

.home__index {
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: $spacing-02;
  column-gap: $spacing-07;
  height: min-content;

  // Leave an empty space in the grid
  &::before {
    content: "";
    grid-column: 1;
    grid-row: 1;
  }
}

.home__index__label {
  grid-column: 2;
  grid-row: 1;
}

// Works section

.home__works {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $spacing-03;
  height: min-content;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.home__works__label {
  grid-column: 1/4;
  grid-row: 1/2;

  @media screen and (max-width: 1280px) {
    grid-column: 1/3;
  }
}
