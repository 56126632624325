html {
  font-family: "Inter", helvetica, sans-serif;
  font-size: 18px;
  background: $color-background;
  color: $color-text;
  line-height: 1.4;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  @include font-settings;
}

body {
  // TODO:: Non mettere negli schermi non retina
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0 $spacing-03;
}

a {
  color: $color-text;

  &:hover {
    text-underline-offset: 3px;
  }

  &:active {
    color: $color-text-3;
  }
}

.modest-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: $spacing-02 0;
}

hr {
  background: $color-text;
  height: 1px;
  border: none;
}

strong {
  font-weight: 600;
}

.reset-headline {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
}

.text-super {
  font-size: 0.6rem;
  vertical-align: super;
  font-weight: 500;
}

.long-form {
  font-size: 0.75rem;
  max-width: 70ch;

  & p {
    margin: 0;
    margin-bottom: $spacing-02;
  }

  & img {
    width: 100%;
    margin-bottom: $spacing-02;
  }
}
