.project-info__image-numbers {
  grid-column: 1/2;
}

.project-info__wrapper {
  display: flex;
  flex-direction: column;
}

.project-info__title {
  grid-column: 2/3;
  max-width: 40ch;
  margin-bottom: $spacing-02;
}
