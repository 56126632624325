.project {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: $spacing-05;
  margin-bottom: $spacing-03;
}

// Index section

.project__index {
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: $spacing-02;
  column-gap: $spacing-07;
  height: min-content;

  // Leave an empty space in the grid
  &::before {
    content: "";
    grid-column: 1;
    grid-row: 1;
  }
}

.project__index__label {
  grid-column: 2;
  grid-row: 1;
}

// Pictures section

.project__pictures {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-auto-rows: min-content;
  gap: $spacing-03;
  height: min-content;
}

.project__pictures__label {
  grid-column: 1/3;
  grid-row: 1/2;
}

.project__pictures__lightbox {
  display: grid;
  height: min-content;

  & img {
    width: 100%;
  }
}

.project__pictures__image-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacing-03;
  height: min-content;
}
