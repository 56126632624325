.news__previews-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: $spacing-05;
}

.news-page {
  max-width: max-content;
  margin: 0 auto;
}

.news-page__title__divider {
  margin: 0;
  max-width: 20ch;
}

.news-page__date {
  display: block;
  padding-top: $spacing-01;
}

.news-page__title {
  margin-bottom: $spacing-04;
}
