.project-picture {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.project-picture__image {
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 4 / 3;
}

@supports not (aspect-ratio: 16 / 9) {
  .project-picture__image {
    height: 0;
    padding-top: calc(3 / 4 * 100%);
  }
}

.project-picture__number {
  margin-top: $spacing-01;
}
